/* additional css until gaining some knowledge of sass, gulp and such */

.addon-text{
    margin: 1em 0 0 0;
}

.addon-price {
    font-size: 2rem;
    padding: 10px 20px;
    color: $blue-grey;
    font-weight: bolder;

    small:first-child {
        font-size: 1rem;
        position: relative;
        top: -10px;
    }

    small:last-child {
        font-size: 0.7rem;
        font-weight: 200;
    }
}