

/* ==========================================================================
1. Bulkit Component Overrides
========================================================================== */

/* remove chrome autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.navbar-wrapper.navbar-faded {
    background: $dark-purple;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
}

.navbar-item, .navbar-link {
    color: $white;
}

.hero.is-theme-primary {
    background: $primary;
}

#hero_2.hero.is-theme-primary {
    background:
    linear-gradient(
        rgba($primary, 0.90),
        rgba($primary, 0.90)
    ),
    url(../images/hero_1.jpg);
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
}

.hero.is-theme-primary .subtitle {
    line-height: 2rem;
}

.section.section-secondary {
    background: darken($wild-sand,5%);
}

.section .title.quick-feature {
    color: $shuttle-gray;
}

.section .section-feature-description {
    color: $shuttle-gray;
    line-height: 2rem;
}

.clean-section-title {
    color: $dark-purple;
}

footer.footer-light-left .footer-nav-right .footer-nav-link {
    color: $shuttle-gray;
}

.section-title-wrapper .subtitle,
.clean-section-title:before,
.clean-section-title:after,
footer.footer-light-left .moto {
    color: $shuttle-gray;
    border-color: $dark-purple;
}

.section-title-wrapper .clean-bg-symbol,
.section .title.quick-feature .bg-number {
    color: $shuttle-gray;
    z-index: -1;
}

.section .title-divider {
    background: $dark-purple;
    height: 3px;
}

.slick-custom {
    color: $black;
}

.slick-dots li button:before {
    opacity: initial;
    color: $outer-space;
}

.slick-custom:hover {
    box-shadow: 0 5px 10px rgba(0,0,0,.15) !important;
}

.slick-dots li.slick-active button:before {
    color: $primary !important;
}

.single-image-carousel .slick-dots {
    bottom: -23px !important;
}

.floating-circle {
  background-color: $sushi;
  color: $white;
  box-shadow: -1px 3px 10px 0 rgba(0,0,0,0.15);
}

.flex-card {
    background: $white;
    border: 0;
}

.flex-card.hover-inset:hover {
    box-shadow: none;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

#events .flex-card:last-child {
    margin-bottom: 0;
}

.pricing-card-body {
    // background: lighten($outer-space,5%);
    background: $white;
}

.header-pricing .header-pricing-card .pricing-card-body {
    border: none;
}

.header-pricing .header-pricing-card .pricing-card-body .plan-price {
    color: $shuttle-gray;
}

.header-pricing .header-pricing-card .pricing-card-body ul li {
    color: $shuttle-gray;
    border-color: darken($wild-sand,5%);
}

.button.primary-btn.btn-outlined {
    color: $white;
}

.button.btn-outlined.light-btn:hover {
    color: $black;
}

.button.secondary-btn.btn-outlined {
    border-color: $secondary;
    color: $secondary;
}

footer.footer-light-left .footer-nav-right .footer-nav-link:hover {
    color: $shuttle-gray !important;
    border-color: $dark-purple;
}

.footer {
    background: $wild-sand;
}

#backtotop a {
    background: $darker-purple;
}

body #backtotop a {
    color: $white;
}

#backtotop.visible a:hover {
    background: darken($darker-purple, 10%);
    color: $white;
}

.is-dark-mobile {
    background: $primary;
}

.is-dark-mobile .navbar-menu {
    background: $primary;
}

.navbar.navbar-light.is-dark-mobile {
    background: $primary;
}

.navbar.navbar-wrapper.navbar-fade.navbar-faded .navbar-menu {
     background: $primary;
}

.navbar.navbar-wrapper.navbar-fade.navbar-faded .navbar-menu .navbar-item,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.is-transparent.navbar-light .navbar-menu .navbar-item,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.navbar-faded .navbar-menu .navbar-item {
    color: $white;
}

.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.is-transparent.navbar-light .navbar-menu .navbar-item:hover,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.navbar-faded .navbar-menu .navbar-item:hover {
    color: $black;
}

.navbar.navbar-wrapper.navbar-fade.navbar-faded .navbar-menu .navbar-item .button,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.is-transparent.navbar-light .navbar-menu .button,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.navbar-faded .navbar-menu .button {
    border-color: $white;
    color: $white;
}

.navbar.navbar-wrapper.navbar-fade.navbar-faded .navbar-menu .navbar-item .button:hover,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.is-transparent.navbar-light .navbar-menu .button:hover,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.navbar-faded .navbar-menu .button:hover {
    background: $white;
    border-color: $white;
    color: $black;
}

.navbar.navbar-wrapper.navbar-fade.navbar-faded .menu-toggle .icon-line-top,
.navbar.navbar-wrapper.navbar-fade.navbar-faded .menu-toggle .icon-line-center,
.navbar.navbar-wrapper.navbar-fade.navbar-faded .menu-toggle .icon-line-bottom,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.navbar-faded .icon-line-top,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.navbar-faded .icon-line-center,
.navbar.navbar-wrapper.navbar-fade.is-dark-mobile.navbar-faded .icon-line-bottom {
    background: $white;
}

.navbar.navbar-wrapper.navbar-fade.navbar-faded .navbar-item:hover {
    color: $black;
}

.contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    font-size: initial;
}

.contact-details p,
.contact-details a {
    color: $shuttle-gray;
}

.contact-details a:hover {
    color: $primary;
}

#offer {
    background: $shuttle-gray;
}

.modal {
    z-index: 2000;
}

.modal.is-active.modal-hero .modal-background {
    background: $shuttle-gray;
}

.modal .modal-content {
    background: darken($wild-sand,5%);
}

.modal .modal-content .section {
    padding: 1.5rem;
}

.modal .modal-content .section-title-wrapper {
    margin-top:-40px;
}

.modal .modal-content .flex-card {
    margin: 0;
}

#hero-events.hero.is-theme-primary {
    background:
    linear-gradient(
        rgba($shuttle-gray, 0.90),
        rgba($shuttle-gray, 0.90)
    ),
    url(../images/events_hero.jpg);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
}

#events-modal.modal .modal-content {
    background: $white;
}

#events-modal .button.primary-btn.raised:hover {
    box-shadow: 0 14px 26px -12px rgba(0,0,0,0.42),0 4px 23px 0px rgba(0,0,0,0.12),0 8px 10px -5px rgba(0,0,0,0.2) !important;
}

#events .content {
    display: flex;
    align-items: center;
}

#events .btn-outlined {
    color: $primary;

    &:hover {
        color: $white;
    }
}

.event_details {
    flex: 1;

    & .event_location {
        color: darken(desaturate(adjust-hue(#636466, -220), 1.49), 18.24);
    }

    & .event_attendees {
        color: $title-grey;
    }
}

.event_date {
    text-transform: uppercase;
    color: $primary;
    font-weight: 600;
}


.event_button {
    margin-right: auto;
}

.success {
    background: $sushi;
    color: $white;
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
    padding: 10px;
    border-radius: 4px;
}

.error {
    background: $desire;
    color: $white;
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
    padding: 10px;
    border-radius: 4px;
}

// hide id input element
#eventId {
    position: absolute;
    top: 0;
    left: -10000px;
}

#logoWrapper {
    width: 140px;
    margin: 0 auto 4px auto;
}


/***************************
    Media Queries
****************************/


@media only screen and (min-width: 769px) {

    #events .event_offset {
        margin-top: -98px;
    }

}

@media only screen and (max-width: 928px) {

    #events .content {
        flex-direction: column;
        align-items: unset;
        text-align: center;
    }

    #events .event_button {
        margin: 0;
        padding-top: 20px;
    }

    #events .event_button a {
        width: 100%;
    }


}

@media only screen and (min-width: 1088px) {

    #events .event_offset {
        margin-top: -194px;
    }

}


@media only screen and (max-width: 768px) {
    
    .navbar.navbar-wrapper.navbar-fade.is-transparent.navbar-light .navbar-menu {
        background: $primary;
    }

    .single-image-carousel .carousel-item {
        margin: 0;
        border: solid 2px $casper;
        border-radius: 15px;
    }

    .single-image-carousel .image-wrapper {
        overflow: hidden;
        border-radius: 15px;
    }

}



