

/* ==========================================================================
0. Bulma Variable Overrides
========================================================================== */
$primary: $dark-purple;
$secondary: $shuttle-gray;
// $accent: #06f4b6;


/* ==========================================================================
1. Bulma Component Overrides
========================================================================== */
html {
    background: $wild-sand;
    font-size: 16px; //default 14px
}

body {
    color: $shuttle-gray;
}
